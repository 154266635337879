import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                            "My 2 boys, one being on the spectrum and the other one with ADHD had a blast! Everyone was so nice and respectful and the whole place was so clean! I love that there is finally somewhere to take my boys where there is NO JUDGMENT"</p>
                            <p className="overview">- Kris M.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            "Great staff and very clean facility with lots to do."</p>
                            <p className="overview">- Greg W.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            "Great clean and fun place. All children are welcome. So much to offer every child! "</p>
                            <p className="overview">
                            - Stacey A.</p>
                        </div>

                         <div className="item carousel-item">
                            <p className="testimonial">
                            "This is my new favorite place to take my granddaughter!!! She had so much fun, she played for hours and it didn't feel like we were there that long. Pricing is incredibly affordable!!! This is something she could do every day and not get tired of it. "</p>
                            <p className="overview">
                            - Samantha D. </p>
                        </div> 
                       
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
